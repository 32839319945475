import React, { Component } from "react";
// import PriceFilter from "./Price"
import {
    Box,
    Button,
    // Checkbox,
    // CheckboxGroup,
    Stack,
    FormLabel,
    FormControl,
    RadioGroup,
    Radio,
    Select
} from '@chakra-ui/react'
// import { isEqual } from 'lodash';
import CurrencySwitcherTabs from '../CurrencySwitcherTabs'
import { FormattedMessage, injectIntl } from 'react-intl';


class Filters extends Component {

    constructor(props) {
        super(props)
        this.state = {
            action: null,
            order: null,
            price: null,
            category: null,
            country: null,
            hadBeenUpdated: false,
            isFiltersMobileOpen: this.props.isFiltersMobileOpen
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => { this.checkUpdated() }, 500);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.delay !== 1000) {
    //         clearInterval(this.interval);
    //         this.interval = setInterval(this.checkUpdated, 1000);
    //     }
    // }

    checkUpdated = () => {
        const { hasBeenUpdated, action, order, price, category, country } = this.state
        if (hasBeenUpdated) {
            this.setState({
                hasBeenUpdated: false
            }, () => {
                this.props.updateFilters({ action, order, price, category, country })
            }
            )
        }
    }

    hideMobileFilters() {
        this.props.hideMobileFilters()
    }

    render() {
        // const updatePrice = (value) => {
        //     this.setState({
        //         [`price`]: value,
        //         hasBeenUpdated: true
        //     }, () => {
        //     });
        // }
        const handleChange = (name, value) => {
            this.setState({
                [`${name}`]: value,
                hasBeenUpdated: true
            }, () => {
            });
        }
        const { isFiltersMobileOpen } = this.state
        const { filters } = this.props
        return (
            <Box
                background={{ base: "white", lg: "transparent" }}
                p={{ base: "2rem", md: "2rem", lg: "0rem" }}
                position={{ base: "fixed", lg: "inherit" }}
                w={{ base: "calc(100vw)", lg: "full%" }}
                h={{ base: "100vh", lg: "auto" }}
                maxH="100%"
                top="0"
                left="5px"
                zIndex="tooltip"
                display={{ base: isFiltersMobileOpen ? "block" : "none", xl: "inherit" }}
                w='full'
            >
                <Stack
                    as="form"
                    spacing={{ base: 4, lg: 8 }}
                    w="100%"
                    h="auto"
                    maxH="100vh"
                >
                    <Button
                        display={{ xl: "none" }}
                        alignSelf="flex-start"
                        leftIcon="arrow-back"
                        onClick={() => { this.hideMobileFilters() }}
                    ><FormattedMessage id="nav.back" /></Button>

                    <FormControl>
                        <FormLabel
                            mb={2}
                            htmlFor="action"
                            fontWeight="bold"
                            fontSize={{ base: "13px" }}
                            textTransform="uppercase"
                        ><FormattedMessage id="filters.type" /> ?</FormLabel>
                        <Select
                            onChange={(e) => { handleChange('action', e.target.value) }}
                            value={filters.action}
                            name="action"
                            id="action"
                            bgColor='white'
                        >
                            <option value='all'>
                                {this.props.intl.formatMessage({ id: "filters.all" })}
                            </option>
                            <option value="sell">
                                {this.props.intl.formatMessage({ id: "filters.type.for.sale" })}
                            </option>
                            <option value="rent">
                                {this.props.intl.formatMessage({ id: "filters.type.for.rent" })}
                            </option>
                            <option value="search">
                                {this.props.intl.formatMessage({ id: "filters.type.looking.for" })}
                            </option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel
                            mb={2}
                            htmlFor="order"
                            fontWeight="bold"
                            fontSize={{ base: "13px" }}
                            textTransform="uppercase"
                        ><FormattedMessage id="filters.sorting" /> ↓</FormLabel>
                        <RadioGroup
                            onChange={(value) => { handleChange('order', value) }}
                            name="order"
                            // value={this.state.order}
                            defaultValue={filters.order}
                            spacing={2}>
                            <Radio
                                display={'block'}
                                mb={1}
                                colorScheme="green" value="-fields.publicationDate" key="date_desc">
                                <FormattedMessage id="filters.most.recent" />
                            </Radio>
                            <Radio
                                display={'block'}
                                mb={1}
                                colorScheme="green" value="fields.price" key="price_asc">
                                <FormattedMessage id="filters.cheapest" />
                            </Radio>
                            <Radio
                                display={'block'}
                                mb={1}
                                colorScheme="green" value="-fields.price" key="price_desc">
                                <FormattedMessage id="filters.most.expensive" />
                            </Radio>
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel
                            mb={2}
                            htmlFor="category"
                            fontWeight="bold"
                            fontSize={{ base: "13px" }}
                            textTransform="uppercase"
                        ><FormattedMessage id="filters.categories" /> :</FormLabel>
                        <RadioGroup
                            name="category"
                            defaultValue={filters.category}
                            onChange={(value) => { handleChange('category', value) }}
                            spacing={2}
                            wrap='wrap'
                        >
                            <Radio
                                display={'block'}
                                mb={1}
                                w='full' colorScheme="green" value="all" key="all">
                                <FormattedMessage id="filters.all.together" />
                            </Radio>
                            <Radio
                                display={'block'}
                                mb={1}

                                colorScheme="green" value="isBoat" key="isBoat">
                                <FormattedMessage id="filters.full.boats.only" />

                            </Radio>
                            <Radio
                                display={'block'}
                                mb={1}

                                w='full' colorScheme="green" value="isSail" key="isSail">
                                <FormattedMessage id="filters.sails.only" />

                            </Radio>
                            <Radio
                                display={'block'}
                                mb={1}

                                w='full' colorScheme="green" value="isPart" key="isPart">
                                <FormattedMessage id="filters.parts.only" />

                            </Radio>
                            <Radio
                                display={'block'}
                                mb={1}

                                w='full' colorScheme="green" value="isTrolley" key="isTrolley">
                                <FormattedMessage id="filters.trolleys.only" />
                            </Radio>
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel
                            mb={2}
                            htmlFor="country"
                            fontWeight="bold"
                            fontSize={{ base: "13px" }}
                            textTransform="uppercase"
                        ><FormattedMessage id="filters.countries" /> :</FormLabel>

                        <Select
                            defaultValue={filters.country}
                            placeholder={this.props.intl.formatMessage({ id: 'filters.all' })}
                            onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                            name="country"
                            id="country"
                            bgColor='white'
                        >
                            {/* <option value="all">
                                { this.props.intl.formatMessage({ id:"countries.all" }) }
                            </option> */}
                            <option value="AUS">
                                {this.props.intl.formatMessage({ id: "countries.australia" })}
                            </option>
                            <option value="BEL">
                                {this.props.intl.formatMessage({ id: "countries.belgium" })}
                            </option>
                            <option value="CHE">
                                {this.props.intl.formatMessage({ id: "countries.switzerland" })}
                            </option>
                            <option value="FRA">
                                {this.props.intl.formatMessage({ id: "countries.france" })}
                            </option>
                            <option value="GBR">
                                {this.props.intl.formatMessage({ id: "countries.great.britain" })}
                            </option>
                            <option value="GER">
                                {this.props.intl.formatMessage({ id: "countries.germany" })}
                            </option>
                            <option value="ITA">
                                {this.props.intl.formatMessage({ id: "countries.italy" })}
                            </option>
                            <option value="NLD">
                                {this.props.intl.formatMessage({ id: "countries.netherlands" })}
                            </option>
                            <option value="NZL">
                                {this.props.intl.formatMessage({ id: "countries.new.zealand" })}
                            </option>
                            <option value="POR">
                                {this.props.intl.formatMessage({ id: "countries.portugal" })}
                            </option>
                            <option value="ESP">
                                {this.props.intl.formatMessage({ id: "countries.spain" })}
                            </option>
                            <option value="USA">
                                {this.props.intl.formatMessage({ id: "countries.usa" })}
                            </option>
                        </Select>
                    </FormControl>
                    <Button
                        onClick={() => { this.hideMobileFilters() }}
                        display={{ xl: "none" }}
                        colorScheme="green"
                    >Appliquer</Button>
                    <CurrencySwitcherTabs width="full" />
                </Stack>
            </Box>
        )
    }
}

export default injectIntl(Filters)