import React, { Component } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
    Box,
    Flex,
    Grid,
    Stack,
    Portal
} from "@chakra-ui/react";
import { BiFilter } from 'react-icons/bi';
import AsyncAdsList from './AsyncAdsList';
import Filters from '../Filters';
import { FormattedMessage } from 'react-intl'
import withLocation from "../../utils/withLocation";
import { wrapperPadding } from "../../utils/wrapperPadding";
import Debug from '../../utils/Debug';

const siteConfig = require('../../../config')

class AsyncAdsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isModelsMobileOpen: this.props.isModelsMobileOpen,
            isFiltersMobileOpen: false,
            filters: {
                type: 'all',
                order: '-fields.publicationDate',
                price: null,
                category: 'all',
                country: 'all'
            },
        }
    }

    handleUpdateFilters = (newFilters) => {
        // console.log( 'newFilters', newFilters )
        this.setState({ filters: newFilters, viewIsFiltered: true },
            // this.props.switchViewForBrowsing()

            () => {
                // console.log( 'update filters ')
            }

        )
    }
    render() {
        const { isFiltersMobileOpen, filters } = this.state
        const { universID, modelID, versionID, brandID } = this.props
        const toggleMobileFilters = () => {
            this.setState({ isFiltersMobileOpen: isFiltersMobileOpen ? false : true }, () => { })
        }
        return (
            <Grid
                id="annonces"
                templateColumns={{ base: "100%", xl: "25% 75%" }}
                gridGap={"0px"}
            >
                <Box as="aside"
                    position="relative"
                    zIndex="base"
                >
                    <Stack
                        spacing={{ base: 0, xl: 2 }}
                        w="100%"
                        position="sticky"
                        top="1rem"

                    >
                        <Box
                            to={siteConfig.links.publish[process.env.GATSBY_LANG]}
                            href={siteConfig.links.publish[process.env.GATSBY_LANG]}
                            target='_blank'
                            mt={5}
                            as={this.props.context === 'iframe' ? 'a' : GatsbyLink}
                            display={{ base: "none", xl: "block" }}
                            backgroundColor="blue.brand"
                            color="white"
                            padding="8px 10px"
                            borderRadius="4px"
                            fontSize="14px"
                            fontWeight="bold"
                            w="100%"
                            textAlign="center"
                            hover={{
                                background: "blue.300"
                            }}
                        >
                            <FormattedMessage id="cta.publish.in.three.minutes" />
                        </Box>

                        <Filters
                            key={`isFiltersMobileOpen${isFiltersMobileOpen}`}
                            updateFilters={(value) => { this.handleUpdateFilters(value) }}
                            minPrice={this.props.minPrice}
                            maxPrice={this.props.maxPrice}
                            isFiltersMobileOpen={isFiltersMobileOpen}
                            hideMobileFilters={toggleMobileFilters}
                            filters={filters}
                        />
                        <Portal>
                            <Flex
                                onClick={toggleMobileFilters}

                                backgroundColor="green.600"
                                borderRadius="md"
                                color="white"
                                p=".5rem 1rem"
                                alignItems={'center'}
                                fontWeight={'500'}
                                zIndex="tooltip"
                                role='button'
                                position={{ base: "fixed", xl: "initial" }}
                                bottom="1rem"
                                left="1rem"
                                display={{ base: 'flex', lg: "none" }}
                                wrap='nowrap'

                            >
                                <Box mr={1}>
                                    <BiFilter />
                                </Box>
                                <FormattedMessage id="filters.filters" />
                            </Flex>
                        </Portal>

                    </Stack>
                </Box>

                <Box
                    order={{ base: 1, xl: 2 }}
                    pr="0"
                    // pr={{ xl: "4rem", xxl: "8rem" }}
                    as="section"
                    pl={{ base: '0', xl: "2.5rem" }}
                    w={{ base: "100%" }}
                >
                    {/* <Debug data={ this.props } /> */}
                    <AsyncAdsList
                        key={filters}
                        filters={filters}
                        universID={universID ? universID : null}
                        modelID={modelID ? modelID : null}
                        versionID={versionID ? versionID : null}
                        brandID={brandID ? brandID : null}
                        baseSlug={this.props.baseSlug}
                        openSelf={this.props.openSelf}
                        scrolling={this.props.scrolling}
                    />
                </Box>
            </Grid >
        )
    }
}
export default withLocation(AsyncAdsPanel)